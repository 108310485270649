import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const responsive = {
  desktop: {
    breakpoint: {
      max: 30000,
      min: 320,
    },
    items: 1,
  },
};

export default responsive;

export const newsData = [
  {
    title: "Judges get lessons on mediation to resolve all disputes amicably.",
    date: "May 03, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/01.Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/city/hyderabad/judges-get-lessons-on-mediation-to-resolve-all-disputes-amicably/articleshow/99947785.cms",
  },
  {
    title:
      "Hyderabad: International Sports Centre inaugurated at Kanha Shanti Vanam",
    date: "Feb 27, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/02.Telangana India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Telangana_Today.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://telanganatoday.com/hyderabad-international-sports-centre-inaugurated-at-kanha-shanti-vanam",
  },
  {
    title:
      "'Govt's vision is to become world's most respected, recognised drugs regulator': Union Health Minister",
    date: "Feb 27, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/03.Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/govts-vision-is-to-become-worlds-most-respected-recognised-drugs-regulator-union-health-minister20230227203505/",
  },
  {
    title:
      "2-day Chintan Shivir on drugs' quality regulation, enforcement to begin in Hyderabad tomorrow",
    date: "Feb 25, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/04.Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/2-day-chintan-shivir-on-drugs-quality-regulation-enforcement-to-begin-in-hyderabad-tomorrow20230225232137/",
  },
  {
    title:
      "‘Need to engage more youth to change direction of humanity’ Kovind says Indian tradition of spirituality also focusses on Vasudhaiva Kutumbakam",
    date: "Feb 20, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/05.The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/cities/Hyderabad/need-to-engage-more-youth-to-change-direction-of-humanity/article30721383.ece",
  },
  {
    title: "Take refuge in kriya yoga: YSS chief to public",
    date: "Feb 12, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/06.The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/national/telangana/take-refuge-in-kriya-yoga-yss-chief-to-public/article66500696.ece",
  },
  {
    title: "Commemorative stamp released",
    date: "Feb 02, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/07.The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/national/telangana/commemorative-stamp-released/article66463566.ece",
  },
  {
    title:
      "Government of India awards Daaji – the Spiritual Leader, Founder of Heartfulness and President, Shri Ram Chandra Mission “Padma Bhushan”",
    date: "Jan 27, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/08.Apn News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/APN_News.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.apnnews.com/government-of-india-awards-daaji-the-spiritual-leader-founder-of-heartfulness-and-president-shri-ram-chandra-mission-padma-bhushan/",
  },
  {
    title: "Kanha meditation fest begins with soulful musical recitals",
    date: "Jan 26, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/09.The Hans India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hans_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehansindia.com/news/cities/hyderabad/kanha-meditation-fest-begins-with-soulful-musical-recitals-779222",
  },
  {
    title: "Spiritual leaders from State win Padma Bhushan awards",
    date: "Jan 25, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/10.The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/national/telangana/spiritual-leaders-from-state-win-padma-bhushan-awards/article66433654.ece",
  },
  {
    title:
      "Heartfulness Announces 150 th Birth Anniversary of the Founder of Shri Ram Chandra Mission",
    date: "Jan 23,2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/11.Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/business/business/heartfulness-announces-150-th-birth-anniversary-of-the-founder-of-shri-ram-chandra-mission20230123153524/",
  },
  {
    title: "‘Agriculture alone can save the economy in case of any calamity’",
    date: "Jan 12, 2023",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/12.The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/national/telangana/agriculture-alone-can-save-the-economy-in-case-of-any-calamity/article66369801.ece",
  },
  {
    title: "Telangana: MP CM Shivraj Singh Chouhan visits Kanha Shanti Vanam",
    date: "Oct 30,2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/13. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/telangana-mp-cm-shivraj-singh-chouhan-visits-kanha-shanti-vanam20221030175829/",
  },
  {
    title:
      "In pursuit of happiness! - National Happiness Unicorn awards presented in Hyderabad",
    date: "Aug 22, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/14. The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/national/telangana/in-pursuit-of-happiness/article65794660.ece",
  },
  {
    title: "Meditation helps overcome stress: PV Sindhu",
    date: "Aug 14, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/15.Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/sports/badminton/meditation-helps-overcome-stress-pv-sindhu/articleshow/93559414.cms",
  },
  {
    title: "‘Govt. trying to make Telangana capital of spiritual development’",
    date: "Aug 12, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/16. The Hindu.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/incoming/govt-trying-to-make-telangana-capital-of-spiritual-development/article65761513.ece",
  },
  {
    title:
      "Telangana: Youth from across the world take part in National Tiranga campaign at meditation centre",
    date: "Aug 11,2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/17. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/telangana-youth-from-across-the-world-take-part-in-national-tiranga-campaign-at-meditation-centre20220811182832/",
  },
  {
    title:
      "'Youth Are the Harbingers of Kindness,' says Daaji on heartfulness hosting the three-day International 'Rising With Kindness' Youth Summit",
    date: "Aug 09, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/18.Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/business/business/youth-are-the-harbingers-of-kindness-says-daaji-on-heartfulness-hosting-the-three-day-international-rising-with-kindness-youth-summit20220809140710/",
  },
  {
    title:
      "World's largest meditation centre in Hyderabad gets new post office",
    date: "May 27, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/19. Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/city/hyderabad/worlds-largest-meditation-centre-in-hyderabad-gets-new-post-office/articleshow/91841386.cms",
  },
  {
    title:
      "Sarbananda Sonowal launches 75 crore Suryanamaskar initiative as part of Azadi ka Amrit Mahotsav",
    date: "Jan 04, 2022",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/20.Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/sarbananda-sonowal-launches-75-crore-suryanamaskar-initiative-as-part-of-azadi-ka-amrit-mahotsav20220104002755/",
  },
  {
    title: "Over 1 million participate in virtual event on World Peace Day",
    date: "Sep 22, 2021",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/21. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/over-1-million-participate-in-virtual-event-on-world-peace-day20210922021647/",
  },
  {
    title: "Celebrating India's Olympics feat, with a difference",
    date: "Aug 12, 2021",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/news2-home.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hindu.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindu.com/news/cities/Hyderabad/celebrating-indias-olympics-feat-with-a-difference/article35868849.ece",
  },
  {
    title:
      "WWF India And Shri Ram Chandra Mission Foundation  (Heartfulness Institute) Join Hands To Adopt 50,000 Trees In Hyderabad",
    date: "Aug 09, 2021",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/news4-home.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/India_Education_Diary.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://indiaeducationdiary.in/wwf-india-and-sri-ram-chandra-mission-foundation-heartfulness-institute-join-hands-to-adopt-50000-trees-in-hyderabad/",
  },
  {
    title:
      "Meditation has the power to transform, it changed me as a player and a person, saya Pullela Gopichand",
    date: "Apr 28, 2021",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/22. Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/entertainment/events/hyderabad/meditation-has-the-power-to-transform-it-changed-me-as-a-player-and-a-person-says-pullela-gopichand/articleshow/82277627.cms",
  },
  {
    title:
      "Value-based education holds greater importance in current scenario: Vice President",
    date: "Sep 12, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/23. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/value-based-education-holds-greater-importance-in-current-scenario-vice-president20200912151606/",
  },
  {
    title:
      "Connecting heartfully in a time of social distancing: A LIFEcast by Daaji",
    date: "Apr 15, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/24. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/business/connecting-heartfully-in-a-time-of-social-distancing-a-lifecast-by-daaji20200415110052/",
  },
  {
    title: "President arrives in Hyderabad; governor, CM welcome him",
    date: "Feb 01, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/25. Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/city/hyderabad/president-arrives-in-hyderabad-governor-cm-welcome-him/articleshow/73848350.cms",
  },
  {
    title:
      "Spirituality is India’s most precious gift to world: President Ram Nath Kovind",
    date: "Feb 03, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/26. Times of India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/city/hyderabad/spirituality-is-indias-most-precious-gift-to-world-president-ram-nath-kovind/articleshow/73894672.cms",
  },
  {
    title: "President inaugurates 1,400-acre HQ of Heartfulness Institute",
    date: "Feb 02, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/news1-home.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/BusinessLine.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehindubusinessline.com/news/president-inaugurates-1400-acre-hq-of-heartfulness-institute/article30720282.ece",
  },
  {
    title: "worlds largest meditation centre comes up in hyderabad",
    date: "Jan 24 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/28.The Lokmat Times.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Lokmat_Times.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.lokmattimes.com/national/worlds-largest-meditation-centre-comes-up-in-hyderabad/",
  },
  {
    title: "Rohit stresses on meditation for positivity",
    date: "Jan 04, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/news3-home.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/Times_Of_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://timesofindia.indiatimes.com/city/hyderabad/rohit-stresses-on-meditation-for-positivity/articleshow/73090611.cms",
  },
  {
    title:
      "Rohit Sharma lays foundation stone for international cricket stadium in Hyderabad",
    date: "Jan 03, 2020",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/29. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/sports/others/rohit-sharma-lays-foundation-stone-for-international-cricket-stadium-in-hyderabad20200103230810/",
  },
  {
    title: "‘Brighter Minds’ excel at TMREIS camp",
    date: "Jan 02, 2019",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/30. The Hans India.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/The_Hans_India.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.thehansindia.com/posts/index/Telangana/2019-01-02/Brighter-Minds-excel-at-TMREIS-camp/468025",
  },

  {
    title: "A mission to 'green' India over the next year",
    date: "Jun 04, 2018",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/31. Ani Times.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/national/general-news/a-mission-to-green-india-over-the-next-year201806041608010001/",
  },
  {
    title: "A Telangana 'Hospital' That Gives Dying Trees A Second Life",
    date: "May 01, 2018",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/news5-home.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/NDTV.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.ndtv.com/telangana-news/a-telangana-hospital-that-gives-dying-trees-a-second-life-1845101",
  },
  {
    title: "President Kovind unveils book on self development in Delhi",
    date: "Jan 19, 2018",
    image: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/32. Ani News.png"
        alt="slider"
      />
    ),
    logo: (
      <StaticImage
        placeholder="blurred"
        src="../images/kanha-in-news/logos/ANI.svg"
        alt="slider"
        style={{ maxWidth: "127px" }}
      />
    ),
    link: "https://www.aninews.in/news/health/president-kovind-unveils-book-on-self-development-in-delhi201801191842040001/",
  },
];
