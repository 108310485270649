import React from "react";

import PropTypes from "prop-types";

const ArrowButtons = ({ next, previous }) => {
  return (
    <div className="carousel-button-group">
      <div className="navigations">
        <button type="button" onClick={() => previous()} className="me-3">
          <i className="icon-chevron-left" />
        </button>
        <button type="button" onClick={() => next()}>
          <i className="icon-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default ArrowButtons;

ArrowButtons.defaultProps = {
  next: () => {
    return 0;
  },
  previous: () => {
    return 0;
  },
};

ArrowButtons.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
};
